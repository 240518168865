<template>
  <v-app id="app">
    <v-parallax class="heading" src="@/assets/images/boombox.jpg">
      <h1 class="heading-name">RUSHI PATEL</h1>
      <h2 class="heading-desc">PhD Candidate at Boston University</h2>
      <h2 class="heading-desc">Cloud enabled FPGA application accelerators</h2>
    </v-parallax>
    <v-container class="ps-0">
      <v-row :justify="start">
        <v-col :cols="2" md="3" lg="3" xl="2">
          <SidebarPage />
        </v-col>
        <v-col :cols="10" md="9">
          <HeadshotPage v-if="$vuetify.breakpoint.mobile" />
          <v-spacer class="my-2"/>
          <AboutPage />
          <v-spacer class="my-2"/>
          <ExperiencePage />
          <v-spacer class="my-2"/>
          <ResearchPage />
          <v-spacer class="my-2"/>
          <PublicationsPage />
          <!-- <Contact /> -->
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import HeadshotPage from '@/components/HeadshotPage';
import SidebarPage from '@/components/SidebarPage';
import AboutPage from '@/components/AboutPage';
import ExperiencePage from '@/components/ExperiencePage';
// import Contact from '@/components/Contact';
import ResearchPage from '@/components/ResearchPage';
import PublicationsPage from '@/components/PublicationsPage';

export default {
  name: 'App',
  components: {
    HeadshotPage,
    AboutPage,
    ExperiencePage,
    SidebarPage,
    // Contact,
    ResearchPage,
    PublicationsPage,
  },
  data() {
    return {
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&family=Roboto:wght@400;500;700&display=swap');

/* OVERWRITE DEFAULTS */
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
/* ******************* */

#app {
  background-color: #F5F5F5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.heading{
  background-color: #0b3353;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  height: 50vh;
}
.heading-name{
  font-family: 'Montserrat', sans-serif;
  color: #ef6262;
  font-size: 2rem;
  font-weight: bolder;
  text-shadow: 1px 0 black, 0 1px black, -1px 0 black, 0 -1px black;
  margin: 0;
}
@media all and (min-width: 500px) {
  .heading-name{
    font-size: 3rem;
  }
}
.heading-desc{
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.25rem;
  text-shadow: 1px 0 black, 0 1px black, -1px 0 black, 0 -1px black;
  margin: 0;
}
</style>
