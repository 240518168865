<template>
  <v-card raised>
    <v-card-title class="text-h4 text-sm-h3">Research</v-card-title>
      <v-card-text>
        <ProjectPage v-for="res in researchlist" :key="res.id" v-bind="res"/>
      </v-card-text>
  </v-card>
</template>

<script>
import ProjectPage from '@/components/ProjectsPage';
import ResearchList from '@/assets/content/Research.json';

export default {
  name: "ResearchPage",
  components: {
    ProjectPage,
  },
  data() {
    return {
      researchlist: ResearchList,
    }
  },
}
</script>

<style scoped>
</style>
