<template>
  <v-card flat>
    <v-card-title class="text-h6 text-sm-h5">
      {{title}}
    </v-card-title>
    <v-card-subtitle class="pb-2 text-subtitle-1 text-sm-h6">
      {{date}}
    </v-card-subtitle>
    <v-card-text class="text-body-2 text-sm-body-1">
          <ul
            v-for="(l,i) in list"
            :key="i"
          >
            <li class="text-body-2 text-sm-body-1">{{l}}</li>
          </ul>
    </v-card-text>
  </v-card>
      <!-- <img class="item proj-img" :src="require(`@/assets/images/${img}`)"> -->
</template>

<script>
export default {
  name: 'PositionPage',
  props: {
    id: String,
    title: String,
    date: String,
    list: Array,
  },
}
</script>

<style scoped>
</style>
