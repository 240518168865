<template>
  <v-card raised>
    <v-row class="ma-0 pa-3" justify="center">
      <v-avatar class="elevation-10" size="180">
        <v-img src="@/assets/images/Headshot.jpeg"></v-img>
      </v-avatar>
      <v-card flat color="transparent justify-center">
        <v-card-title class="text-h4 text-sm-h3 justify-center">Rushi Patel</v-card-title>
        <v-card-subtitle class="text-subtitle-2 text-center">HPC acceleration with FPGA architectures</v-card-subtitle>
        <v-card-text>
          <h3 class="text-body-2 text-center">Performace, Security, Communication, Hardware Software co-design</h3>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="px-10" color="primary" elevation="10" rounded :href="`${publicPath}Rushi-Patel_Resume.pdf`">Resume</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>

export default {
  name: "HeadshotPage",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
}
</script>

<style scoped>
</style>