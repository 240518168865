<template>
  <v-card flat>
    <v-card-title class="text-h6 text-sm-h5">
      {{title}}
    </v-card-title>
    <v-card-text class="text-body-2 text-sm-body-1">
      {{desc}}
    </v-card-text>
  </v-card>
      <!-- <img class="item proj-img" :src="require(`@/assets/images/${img}`)"> -->
</template>

<script>
export default {
  name: 'ProjectPage',
  props: {
    id: String,
    title: String,
    img: String,
    desc: String,
  },
}
</script>

<style scoped>
</style>