<template>
  <v-card raised>
    <v-card-title class="text-h4 text-sm-h3">Experience</v-card-title>
    <v-card-text>
      <PositionPage v-for="pos in experiences" :key="pos.id" v-bind="pos" />
    </v-card-text>
  </v-card>
</template>

<script>
import Experiences from '@/assets/content/Experiences.json';
import PositionPage from '@/components/PositionPage';

export default {
  name: "ExperiencePage",
  components: { 
    PositionPage,
  },
  data() {
    return {
      experiences: Experiences,
    }
  },
}
</script>

<style scoped>
</style>
