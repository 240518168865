<template>
  <v-container class="sidebar ma-0" :class="sidebarClass">
    <v-row align="center">
      <v-col :cols="12" v-if="!$vuetify.breakpoint.mobile">
        <v-row class="ma-0" justify="center">
          <v-avatar size="150">
            <v-img src="@/assets/images/Headshot.jpeg"></v-img>
          </v-avatar>
        </v-row>
        <v-card flat color="transparent">
          <v-card-title class="text-h5 text-lg-h4" justify="center">Rushi Patel</v-card-title>
          <v-card-subtitle class="text-subtitle-2">HPC acceleration with FPGA architectures</v-card-subtitle>
          <v-card-text>
            <h3 class="text-body-2">Performace, Security, Communication, Hardware Software co-design</h3>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn class="px-8" outlined rounded :href="`${publicPath}Rushi-Patel_Resume.pdf`">Resume</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-list color="transparent">
          <v-list-item 
            v-for="link in linkList" 
            :key="link"
            class="px-0"
            link
            :href="personalinfo[link]"
          >
            <v-list-item-content v-if="!$vuetify.breakpoint.mobile"
              class="ps-4"
            >
              <v-list-item-title v-text="link" class="pl-2"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="pa-0">
              <v-img contain class="img" :src="require(`@/assets/images/${link}.svg`)"></v-img>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PersonalInfo from '@/assets/content/PersonalInfo.json';

export default {
  name: 'SidebarPage',
  data() {
    return{
      publicPath: process.env.BASE_URL,
      personalinfo: PersonalInfo,
      linkList: ["Scholar", "Github", "LinkedIn", "Youtube", "Twitch", "Email"],
    }
  },
  computed: {
    sidebarClass: function() {
      return {
        'sidebar-small': this.$vuetify.breakpoint.mobile,
        'sidebar-full': !this.$vuetify.breakpoint.mobile,
      }
    }
  }
}
</script>

<style scoped>
.sidebar{
  background-color: #a3cbe6;
  padding: 0.5rem;
  position: sticky;
}
.sidebar-small{
  top: calc(50vh - (90px + 6rem) );
  border-radius: 0 2rem 2rem 0;
  width: 60px;
}
.sidebar-full{
  top: 0px;
}
.hidden{
  display: none;
}

.img{
  height: 30px;
}

</style>