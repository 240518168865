<template>
  <v-card raised>
    <v-card-title class="text-h4 text-sm-h3">About Me</v-card-title>
    <v-card-text>
      <span class="text-body-2 text-lg-body-1" v-html="aboutme"></span>
    </v-card-text>
  </v-card>
</template>

<script>
import AboutMe from '@/assets/content/about_me.txt'

export default {
  name: "AboutPage",
  data() {
    return {
      aboutme: AboutMe,
    }
  },
}
</script>

<style scoped>
</style>