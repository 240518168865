<template>
  <v-card raised>
    <v-card-title class="text-h4 text-sm-h3">Publications</v-card-title>
    <v-list class="px-4">
      <v-list-item
        v-for="pub in pubs"
        :key="pub.id"
        link
        dense
        :href="pub.link"
      >
        <v-list-item-content class="text-body-2 text-lg-body-1" v-html="stringPub(pub)"></v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import Pubs from '@/assets/content/Publications.json';

export default {
  name: 'PublicationsPage',
  components: {
  },
  props: {
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      pubs: Pubs,
    }
  },
  computed: {
  },
  methods: {
    stringPub: function(record) {
      var rtn = "";
      var journalInfo = [];
      var authorListArray = record.author.map((a) => {
        const spaceIndex = a.name.indexOf(" ");
        var AuthorLast = a.name.substr(0,spaceIndex);
        var AuthorRemain = a.name.substr(spaceIndex+1);
        if (a.name.includes("Patel"))
          return `<strong>${AuthorRemain} ${AuthorLast}</strong>`
        return `${AuthorRemain} ${AuthorLast}`;
      });
      const lastauthor = authorListArray.pop();
      const authorList = authorListArray.join(" ") + ' and ' + lastauthor;
      if (record.journal)
      {
        journalInfo.push(record.journal.name);
        if (record.journal.volume) 
          journalInfo.push(record.journal.volume);
        if (record.journal.number) 
          journalInfo.push(record.journal.number);
        if (record.journal.pages) 
          journalInfo.push(record.journal.pages);
        rtn = `<p> ${authorList} "${record.title}," in ${journalInfo.join(", ")}, ${record.year}.`
      }
      else
        rtn = `<p> ${authorList} "${record.title}," in ${record.booktitle}, ${record.year}.`
      if (record.note)
        rtn += ` ${record.note}.` 
      return rtn + '</p>';
    },
  },
}
</script>

<style scoped>
</style>